<template>
  <div v-show="!isHidden" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <div style="display: flex; width: 100%;">
        <el-color-picker
          v-model="localValue"
          :size="size"
          :disabled="_isReadonly"
          @change="$emit('input', localValue)"
        ></el-color-picker>
        <el-input
          v-model="localValue"
          :name="name"
          :size="size"
          :placeholder="placeholder || $locale.main.placeholder.string_field"
          :readonly="_isReadonly"
          @change="$emit('input', localValue)"
        ></el-input>
      </div>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import InputLabel from '@/mixins/inputLabel.js'

export default {
  name: 'a-color-picker',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, registryMixin, InputLabel],
  props: {
    value: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    label: {
      type: String,
      description: 'name'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    size: {
      type: String,
      description: 'size',
      editor: 'Size'
    },
    width: {
      type: String,
      description: 'width'
    },
    tooltip: {
      type: String,
      description: 'tooltip'
    },
    placeholder: {
      type: String,
      description: 'placeholder'
    }
  },
  data () {
    return {
      localValue: this.value || '#409EFF'
    }
  },
  mounted () {
    this.$emit('input', this.localValue)
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  }
}
</script>

<style scoped>
</style>
